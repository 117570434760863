@import '../abstracts/variables';
@import '../abstracts/functions';
@import '../abstracts/mixins';

// gnb
.header {
  &__logo {
    &-square {
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.45s cubic-bezier(0.55, 0, 0.1, 1), visibility 0s 0.45s;
    }
    &.s-show {
      .header__logo-square {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.45s cubic-bezier(0.55, 0, 0.1, 1), visibility 0s 0s;
      }
    }
  }
}

// kv
.section-kv {
  $sectionKv: &;
  overflow: hidden;
  position: relative;
  width: 100%;
  // height: calc(var(--height) * 1px);
  height: 100vh;
  background-color: #c9c0b2;
  // @at-root html.s-device--mobile & {
  //   background-color: #c1b8a8;
  // }
  @include only-mobile {
    height: calc(var(--height) * 1px);
  }
  &__vid {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    // -webkit-filter: brightness(100%);
    &--pc {
      height: 100%;
      object-fit: cover;
      @include only-tablet {
        @media (orientation: portrait) {
          display: none;
        }
      }
    }
    &--ta {
      @include only-tablet {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media (orientation: landscape) {
          display: none;
        }
      }
    }
    &--mo {
      @include only-mobile {
        height: 100%;
        top: 0;
        left: 0;
        transform: translate(0, 0);
        object-fit: cover;
      }
    }
  }
  &__txt-wrap {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%) translateX(240rem);
    opacity: 0;
    transition: left 1.5s, opacity 2s;
    &.s-show {
      left: 50%;
      opacity: 1;
    }
    @include only-tablet {
      transform: translate(-50%, -50%) translate(175rem, 0);
      @media (orientation: portrait) {
        top: auto;
        bottom: 20%;
        left: 0;
        transform: none;
        width: 100%;
        padding: 0 50rem;
        transition: bottom 0.8s, opacity 2s;
        &.s-show {
          left: 0;
          bottom: 80rem;
        }
      }
    }
    @include only-mobile {
      top: auto;
      left: 0;
      bottom: 20%;
      transform: none;
      width: 100%;
      padding: 0 15rem;
      transition: bottom 0.8s, opacity 2s;
      &.s-show {
        left: 0;
        bottom: 50rem;
      }
    }
  }
  &__txt {
    font-weight: 500;
    font-size: 64rem;
    line-height: 0.94;
    letter-spacing: -1.5rem;
    @include only-tablet {
      font-size: 36rem;
      line-height: 0.94;
      letter-spacing: -0.5rem;
      @media (orientation: portrait) {
        font-size: 64rem;
        line-height: 0.94;
        letter-spacing: -1rem;
      }
    }
    @include only-mobile {
      font-size: 32rem;
      line-height: 1;
      letter-spacing: -0.5rem;
    }
    &.c-text--start {
      + #{$sectionKv}__subtxt {
        animation: ShowSubText 1.5s cubic-bezier(0.33, -0.11, 0.65, 0.93) 0.35s forwards;
      }
    }
  }
  &__subtxt {
    margin: 26rem 0 0;
    font-size: 24rem;
    line-height: 1;
    letter-spacing: -0.4rem;
    @include not-desktop {
      margin: 16rem 0 0;
    }
    @include only-tablet {
      font-size: 16rem;
      line-height: 1;
      letter-spacing: -0.2rem;
      @media (orientation: portrait) {
        margin: 30rem 0 0;
        font-size: 28rem;
        line-height: 1.14;
        letter-spacing: -0.3rem;
      }
    }
    @include only-mobile {
      font-size: 14rem;
      letter-spacing: -0.2rem;
    }
  }
  // ko
  @include lang-ko {
    #{$sectionKv} {
      &__txt-wrap {
        @include only-desktop {
          transform: translate(-50%, -50%) translate(205rem, 0);
        }
        @include only-tablet {
          transform: translate(-50%, -50%) translate(170rem, 0);
          @media (orientation: portrait) {
            top: auto;
            bottom: 80rem;
            left: 0;
            transform: none;
            width: 100%;
            padding: 0 50rem;
          }
        }
        // @include only-mobile {
        //   top: auto;
        //   bottom: 50rem;
        //   left: 0;
        //   transform: none;
        //   padding: 0 15rem;
        // }
      }
      &__txt {
        font-weight: 700;
        font-size: 76rem;
        line-height: 1.13;
        letter-spacing: -1rem;
        @include only-tablet {
          font-size: 48rem;
          line-height: 1.17;
          letter-spacing: -0.3rem;
          @media (orientation: portrait) {
            font-size: 72rem;
            letter-spacing: -0.5rem;
          }
        }
        @include only-mobile {
          font-size: 32rem;
          line-height: 1.13;
          letter-spacing: -0.3rem;
        }
      }
      &__subtxt {
        margin: 25rem 0 0;
        font-weight: 500;
        font-size: 28rem;
        line-height: 1.29;
        letter-spacing: -0.4rem;
        @include only-tablet {
          margin: 15rem 0 0;
          font-size: 18rem;
          line-height: 1.33;
          letter-spacing: -0.2rem;
          @media (orientation: portrait) {
            margin: 30rem 0 0;
            font-size: 26rem;
            line-height: 1.38;
            letter-spacing: -0.3rem;
          }
        }
        @include only-mobile {
          margin: 10rem 0 0;
          font-size: 14rem;
          line-height: 1.29;
          letter-spacing: -0.1rem;
        }
      }
    }
  }
}
@keyframes ShowSubText {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// track
.section-track {
  $this: '.section-track';
  position: relative;
  height: 400vh;
  @include breakpoint($tabletMin, min) {
    --nth1-width: calc(190 / #{$desktopMax} * var(--width) * 1px);
    --nth1-height: calc(70 / 1080 * 100vh);
    --nth2-width: calc(562 / #{$desktopMax} * var(--width) * 1px);
    --nth2-height: calc(381 / 1080 * 100vh);
    --nth3-width: calc(1146 / #{$desktopMax} * var(--width) * 1px);
    --nth3-height: calc(746 / 1080 * 100vh);
    --nth4-width: calc(var(--width) * 1px);
    --nth4-height: 100vh;
    z-index: 50;
  }
  @include only-mobile {
    --nth1-width: calc(70 / #{$mobileMin} * var(--width) * 1px);
    --nth1-height: calc(70rem * 0.68571429);
    --nth2-width: calc(170 / #{$mobileMin} * var(--width) * 1px);
    --nth2-height: calc(170rem * 1.14117647);
    --nth3-width: calc(272 / #{$mobileMin} * var(--width) * 1px);
    --nth3-height: calc(272rem * 1.38970588);
    --nth4-width: calc(var(--width) * 1px);
    --nth4-height: calc(var(--width) * 1px * 1.49333333);
    height: auto;
  }
  &__cont {
    overflow: hidden;
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    @include only-mobile {
      position: relative;
      height: auto;
    }
  }
  &__inr {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #e8dcc5;
    @include only-mobile {
      position: relative;
      height: calc(var(--width) * 1px * 1.49333333);
      background: none;
      @at-root .section-track--is-active & {
        height: calc(var(--width) * 1px * 1.49333333);
      }
    }
  }
  &__tit {
    font-family: $grotesk-family;
  }
  &__unit {
    display: inline-block;
    vertical-align: bottom;
  }
  &__item {
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: 0;
    text-transform: uppercase;
    @for $i from 1 through 4 {
      &:nth-child(#{$i}) {
        z-index: calc(4 - #{$i});
      }
    }
    &:nth-child(1) {
      #{$this}__head {
        display: flex;
        align-items: center;
        width: calc(var(--nth1-width));
        height: calc(var(--nth1-height));
        padding: 0 0 0 20rem;
        background: #fff;
        opacity: 1;
        @include only-tablet {
          padding: 0 0 0 15rem;
        }
        @include only-mobile {
          padding: 0 0 0 10rem;
        }
      }
      #{$this}__tit {
        font-weight: 500;
        font-size: 20rem;
        letter-spacing: -0.2rem;
        @include only-tablet {
          font-size: 16rem;
          letter-spacing: normal;
        }
        @include only-mobile {
          font-size: 12rem;
          letter-spacing: -0.3rem;
        }
      }
    }
    &:nth-child(2) {
      width: calc(
        var(--nth1-width) - var(--nth1-width) * min(var(--progress, 0), 0.3) * (10 / 3) + var(--nth2-width) * min(var(--progress, 0), 0.3) *
          (10 / 3)
      );
      height: calc(
        var(--nth1-height) - var(--nth1-height) * min(var(--progress, 0), 0.3) * (10 / 3) + var(--nth2-height) *
          min(var(--progress, 0), 0.3) * (10 / 3)
      );
      background: #fbf6ee;
      @include breakpoint($tabletMin, min) {
        width: calc(
          var(--nth1-width) - var(--nth1-width) * min(var(--progress, 0), 0.3) * (10 / 3) + var(--nth2-width) * min(var(--progress, 0), 0.3) *
            (10 / 3)
        );
        height: calc(
          var(--nth1-height) - var(--nth1-height) * min(var(--progress, 0), 0.3) * (10 / 3) + var(--nth2-height) *
            min(var(--progress, 0), 0.3) * (10 / 3)
        );
      }
      @include only-mobile {
        width: var(--nth2-width);
        height: var(--nth2-height);
      }
      #{$this}__head {
        @include breakpoint($tabletMin, min) {
          opacity: calc(max(var(--progress, 0.15) - 0.15, 0) * (10 / 1.5));
        }
        @include only-mobile {
          opacity: 1;
        }
      }
      #{$this}__tit {
        font-weight: 500;
        font-size: 32rem;
        line-height: 1.06;
        letter-spacing: -0.5rem;
        @include only-tablet {
          font-size: 20rem;
          line-height: 1;
          letter-spacing: -0.2rem;
        }
        @include only-mobile {
          font-size: 12rem;
          line-height: 1;
          letter-spacing: -0.3rem;
        }
      }
      #{$this}__value {
        font-weight: 500;
        font-size: 60rem;
        line-height: 1;
        letter-spacing: -2.5rem;
        @include only-tablet {
          font-size: 30rem;
          line-height: 1;
          letter-spacing: -0.5rem;
        }
        @include only-mobile {
          font-size: 20rem;
          line-height: 1;
          letter-spacing: normal;
        }
      }
      #{$this}__desc {
        margin-top: 47rem;
        font-size: 20rem;
        line-height: 1.1;
        letter-spacing: -0.3rem;
        @include only-tablet {
          margin-top: 20rem;
          font-size: 14rem;
          line-height: 1;
          letter-spacing: -0.1rem;
        }
        @include only-mobile {
          margin-top: 22rem;
          font-size: 10rem;
          line-height: 1;
          letter-spacing: -0.2rem;
        }
      }
    }
    &:nth-child(3) {
      width: calc(
        var(--nth1-width) - var(--nth1-width) * min(var(--progress, 0), 0.3) * (10 / 3) + var(--nth2-width) * min(var(--progress, 0), 0.3) *
          (10 / 3) - var(--nth2-width) * min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * (10 / 3) + var(--nth3-width) *
          min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * (10 / 3)
      );
      height: calc(
        var(--nth1-height) - var(--nth1-height) * min(var(--progress, 0), 0.3) * (10 / 3) + var(--nth2-height) *
          min(var(--progress, 0), 0.3) * (10 / 3) - var(--nth2-height) * min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * (10 / 3) +
          var(--nth3-height) * min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * (10 / 3)
      );
      background: #f3eade;
      @include breakpoint($tabletMin, min) {
        width: calc(
          var(--nth1-width) - var(--nth1-width) * min(var(--progress, 0), 0.3) * (10 / 3) + var(--nth2-width) * min(var(--progress, 0), 0.3) *
            (10 / 3) - var(--nth2-width) * min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * (10 / 3) + var(--nth3-width) *
            min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * (10 / 3)
        );
        height: calc(
          var(--nth1-height) - var(--nth1-height) * min(var(--progress, 0), 0.3) * (10 / 3) + var(--nth2-height) *
            min(var(--progress, 0), 0.3) * (10 / 3) - var(--nth2-height) * min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * (10 / 3) +
            var(--nth3-height) * min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * (10 / 3)
        );
      }
      @include only-mobile {
        width: var(--nth3-width);
        height: var(--nth3-height);
      }
      #{$this}__head {
        @include breakpoint($tabletMin, min) {
          opacity: calc(max(var(--progress, 0.45) - 0.45, 0) * (10 / 1.5));
        }
        @include only-mobile {
          opacity: 1;
        }
      }
      #{$this}__tit {
        font-weight: 500;
        font-size: 48rem;
        line-height: 1.04;
        letter-spacing: -1.5rem;
        @include only-tablet {
          font-size: 28rem;
          line-height: 1;
          letter-spacing: -0.2rem;
        }
        @include only-mobile {
          font-size: 18rem;
          line-height: 1;
          letter-spacing: -0.5rem;
        }
      }
      #{$this}__value {
        font-weight: 500;
        font-size: 88rem;
        line-height: 0.91;
        letter-spacing: -3.5rem;
        @include only-tablet {
          font-size: 42rem;
          line-height: 0.95;
          letter-spacing: -1rem;
        }
        @include only-mobile {
          font-size: 32rem;
          line-height: 0.72;
          letter-spacing: -1rem;
        }
      }
      #{$this}__desc {
        font-size: 24rem;
        line-height: 1.08;
        letter-spacing: -0.4rem;
        @include only-tablet {
          font-size: 16rem;
          line-height: 1;
          letter-spacing: -0.1rem;
        }
        @include only-mobile {
          font-size: 12rem;
          line-height: 1;
          letter-spacing: -0.1rem;
        }
      }
    }
    &:nth-child(4) {
      width: calc(
        var(--nth1-width) - var(--nth1-width) * min(var(--progress, 0), 0.3) * (10 / 3) + var(--nth2-width) * min(var(--progress, 0), 0.3) *
          (10 / 3) - var(--nth2-width) * min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * (10 / 3) + var(--nth3-width) *
          min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * (10 / 3) - var(--nth3-width) * min(max(var(--progress, 0), 0.3) - 0.6, 0.3) *
          (10 / 3) + var(--nth4-width) * min(max(var(--progress, 0), 0.3) - 0.6, 0.3) * (10 / 3)
      );
      height: calc(
        var(--nth1-height) - var(--nth1-height) * min(var(--progress, 0), 0.3) * (10 / 3) + var(--nth2-height) *
          min(var(--progress, 0), 0.3) * (10 / 3) - var(--nth2-height) * min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * (10 / 3) +
          var(--nth3-height) * min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * (10 / 3) - var(--nth3-height) *
          min(max(var(--progress, 0), 0.3) - 0.6, 0.3) * (10 / 3) + var(--nth4-height) * min(max(var(--progress, 0), 0.3) - 0.6, 0.3) *
          (10 / 3)
      );
      background: #e8dcc5;
      @include breakpoint($tabletMin, min) {
        width: calc(
          var(--nth1-width) - var(--nth1-width) * min(var(--progress, 0), 0.3) * (10 / 3) + var(--nth2-width) * min(var(--progress, 0), 0.3) *
            (10 / 3) - var(--nth2-width) * min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * (10 / 3) + var(--nth3-width) *
            min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * (10 / 3) - var(--nth3-width) * min(max(var(--progress, 0), 0.3) - 0.6, 0.3) *
            (10 / 3) + var(--nth4-width) * min(max(var(--progress, 0), 0.3) - 0.6, 0.3) * (10 / 3)
        );
        height: calc(
          var(--nth1-height) - var(--nth1-height) * min(var(--progress, 0), 0.3) * (10 / 3) + var(--nth2-height) *
            min(var(--progress, 0), 0.3) * (10 / 3) - var(--nth2-height) * min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * (10 / 3) +
            var(--nth3-height) * min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * (10 / 3) - var(--nth3-height) *
            min(max(var(--progress, 0), 0.3) - 0.6, 0.3) * (10 / 3) + var(--nth4-height) * min(max(var(--progress, 0), 0.3) - 0.6, 0.3) *
            (10 / 3)
        );
      }
      @include only-mobile {
        width: var(--nth4-width);
        height: var(--nth4-height);
      }
      #{$this}__head {
        @include breakpoint($tabletMin, min) {
          opacity: calc(max(var(--progress, 0.75) - 0.75, 0) * (10 / 1.5));
        }
        @include only-mobile {
          opacity: 1;
        }
      }
      #{$this}__tit {
        font-weight: 500;
        font-size: 80rem;
        line-height: 1;
        letter-spacing: -3.5rem;
        text-transform: uppercase;
        @include only-tablet {
          font-size: 32rem;
          line-height: 1.06;
          letter-spacing: -0.3rem;
        }
        @include only-mobile {
          font-size: 24rem;
          line-height: 1;
          letter-spacing: -0.5rem;
        }
      }
      #{$this}__value {
        font-weight: 500;
        font-size: 104rem;
        line-height: 1;
        letter-spacing: -3.5rem;
        @include only-tablet {
          font-size: 46rem;
          line-height: 1;
          letter-spacing: -1rem;
        }
        @include only-mobile {
          font-size: 40rem;
          line-height: 1;
          letter-spacing: -1rem;
        }
      }
      #{$this}__desc {
        margin-top: 100rem;
        font-size: 32rem;
        line-height: 1;
        letter-spacing: -0.5rem;
        @include only-tablet {
          margin-top: 40rem;
          font-size: 20rem;
          line-height: 1;
          letter-spacing: -0.3rem;
        }
        @include only-mobile {
          margin-top: 29rem;
          font-size: 14rem;
          line-height: 1.14;
          letter-spacing: -0.1rem;
        }
      }
      #{$this}__unit {
        margin-bottom: 21rem;
        font-weight: 700;
        font-size: 48rem;
        line-height: 1;
        letter-spacing: -1.5rem;
        @include only-tablet {
          margin-bottom: 8rem;
          font-size: 24rem;
          line-height: 1;
          letter-spacing: -0.5rem;
        }
        @include only-mobile {
          margin-bottom: 8rem;
          font-size: 24rem;
          line-height: 1;
          letter-spacing: -0.5rem;
        }
      }
    }
  }
  &__head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 24rem 20rem;
    opacity: 0;
    @include only-mobile {
      padding: 14rem 10rem;
    }
  }
  &__info {
    text-align: right;
  }
  @include lang-ko {
    #{$this} {
      &__item {
        &:nth-child(2) {
          #{$this} {
            &__tit {
              font-weight: 700;
              @include only-mobile {
                font-size: 12rem;
              }
            }
            &__value {
              font-family: $grotesk-family;
            }
            &__desc {
              font-weight: 500;
              line-height: normal;
              @include only-tablet {
                margin-top: 26rem;
                font-size: 16rem;
                line-height: 1;
              }
              @include only-mobile {
                margin-top: 21rem;
                font-size: 12rem;
                line-height: 0.83;
              }
            }
            &__unit {
              margin-bottom: 7rem;
              margin-left: 4rem;
              @include only-tablet {
                margin-bottom: 3rem;
                margin-left: 2rem;
              }
              @include only-mobile {
                margin-bottom: 2rem;
                margin-left: 1rem;
              }
            }
          }
        }
        &:nth-child(3) {
          #{$this} {
            &__tit {
              font-weight: 700;
              line-height: 1.17;
              @include only-tablet {
                font-size: 24rem;
              }
              @include only-mobile {
                font-size: 16rem;
                line-height: 1.13;
              }
            }
            &__value {
              font-family: $grotesk-family;
              @include only-mobile {
                font-size: 28rem;
                line-height: 0.82;
              }
            }
            &__desc {
              font-weight: 500;
              font-size: 32rem;
              line-height: 1.16;
              @include only-tablet {
                font-size: 20rem;
                line-height: 1.1;
              }
              @include only-mobile {
                margin-top: 36rem;
                font-size: 14rem;
                line-height: 1.14;
              }
            }
            &__unit {
              margin: 0 0 9rem 8rem;
              font-weight: 700;
              font-size: 40rem;
              line-height: 1.13;
              letter-spacing: normal;
              @include not-desktop {
                line-height: 1;
                letter-spacing: -0.5rem;
              }
              @include only-tablet {
                margin: 0 0 3rem 5rem;
                font-size: 24rem;
              }
              @include only-mobile {
                margin: 0 0 0 3rem;
                font-size: 20rem;
              }
              &:first-child {
                position: relative;
                top: -43rem;
                margin: 0 10rem 0 0;
                font-weight: normal;
                font-size: 24rem;
                line-height: 0.83;
                @include only-tablet {
                  top: -20rem;
                  margin: 0 4rem 0 0;
                  font-size: 16rem;
                  line-height: 1;
                  letter-spacing: -0.2rem;
                }
                @include only-mobile {
                  top: -8rem;
                  margin: 0 3rem 0 0;
                  font-size: 12rem;
                  line-height: 1;
                  letter-spacing: -0.3rem;
                }
              }
            }
          }
        }
        &:nth-child(4) {
          #{$this} {
            &__tit {
              font-weight: 500;
              @include only-tablet {
                font-size: 20rem;
                line-height: 1.1;
              }
            }
            &__value {
              font-family: $grotesk-family;
              @include only-mobile {
                margin-top: -4rem;
                font-size: 36rem;
                line-height: 1.11;
                letter-spacing: -0.9rem;
              }
            }
            &__unit {
              font-weight: 700;
              margin-bottom: 17rem;
              margin-left: 6rem;
              @include only-tablet {
                margin-bottom: 5rem;
                margin-left: 4rem;
              }
              @include only-mobile {
                margin-bottom: 7rem;
                margin-left: 2rem;
              }
              &:first-child {
                position: relative;
                top: -48rem;
                margin: 0 13rem 0 0;
                font-weight: normal;
                font-size: 28rem;
                line-height: 1.29;
                @include not-desktop {
                  line-height: 1;
                  letter-spacing: -0.3rem;
                }
                @include only-tablet {
                  top: -22rem;
                  margin: 0 5rem 0 0;
                  font-size: 16rem;
                }
                @include only-mobile {
                  top: -19rem;
                  margin: 0 6rem 0 0;
                  font-size: 12rem;
                }
              }
            }
            &__desc {
              font-weight: 500;
              font-size: 40rem;
              line-height: 1.13;
              @include only-tablet {
                font-size: 22rem;
                line-height: 1.18;
                letter-spacing: normal;
              }
              @include only-mobile {
                margin-top: 23rem;
                font-size: 16rem;
                line-height: 1.13;
              }
            }
          }
        }
      }
      &__head {
        @include only-mobile {
          padding: 14rem 13rem;
        }
      }
    }
  }
}

// strategy
.section-str {
  $sectionStr: &;
  height: 100vh;
  @include not-desktop {
    height: auto;
  }
  &__con {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 150rem 0;
    // padding: 120rem 0;
    @media (min-aspect-ratio: 442/294) {
      padding: 14.17vh 0;
      // padding: 11.11vh 0;
    }
    @include only-tablet {
      padding: 80rem 0;
      @media (orientation: landscape) {
        padding: 7.4vh 0;
      }
    }
    @include only-mobile {
      padding: 60rem 0;
    }
  }
  &__tit {
    position: relative;
    margin: 0 232rem;
    @include not-desktop {
      margin: 0 15rem;
    }
    &-eyebrow {
      position: absolute;
      top: 3rem;
      left: 0;
      font-weight: 500;
      font-size: 20rem;
      line-height: 1;
      letter-spacing: 0.5rem;
      @media (min-aspect-ratio: 442/294) {
        font-size: 1.85vh;
      }
      @include only-tablet {
        top: 2rem;
        font-size: 14rem;
        line-height: 1;
        letter-spacing: -0.1rem;
      }
      @include only-mobile {
        font-size: 12rem;
        line-height: 1;
        letter-spacing: -0.1rem;
      }
    }
    &-txt {
      font-family: $fruit-family;
      font-weight: 500;
      font-size: 52rem;
      line-height: 1;
      letter-spacing: -0.3rem;
      text-indent: 158rem;
      @media (min-aspect-ratio: 442/294) {
        font-size: 4.67vh;
      }
      @include only-tablet {
        padding: 0 50rem 0 0;
        font-size: 26rem;
        line-height: 1.08;
        letter-spacing: -0.2rem;
        text-indent: 168rem;
      }
      @include only-mobile {
        font-size: 26rem;
        line-height: 1;
        letter-spacing: -0.1rem;
        text-indent: 110rem;
      }
    }
    &-top {
      display: inline-block;
      margin-bottom: 4rem;
      font-size: 52rem;
      @media (min-aspect-ratio: 442/294) {
        font-size: 4.67vh;
      }
      @include only-tablet {
        display: inline;
        font-size: 26rem;
        line-height: 1.08;
        letter-spacing: -0.2rem;
      }
      @include only-mobile {
        display: inline;
        font-size: 26rem;
        line-height: 1;
        letter-spacing: -0.1rem;
      }
    }
  }
  &__inr {
    height: 100%;
    margin: 49rem 0 0;
    @media (min-aspect-ratio: 442/294) {
      margin: 4.63vh 0 0;
    }
    @include only-tablet {
      margin: 20rem 0 0;
    }
    @include only-mobile {
      margin: 20rem 0 0;
    }
  }
  &__list {
    height: 100%;
    padding: 0 232rem;
    @include only-tablet {
      padding: 0 15rem;
    }
    @include only-mobile {
      padding: 0 15rem;
    }
  }
  @at-root .c-strategy {
    $strategy: &;
    &__itm {
      @include only-tablet {
        height: 432rem;
      }
      @include only-mobile {
        height: 420rem;
      }
      + #{&} {
        @include only-mobile {
          margin: 15rem 0 0;
        }
      }
      &-inr {
        @include only-tablet {
          padding: 45rem 25rem 47rem;
        }
        @include only-mobile {
          padding: 50rem 30rem 40rem;
        }
      }
      &-img-wrap {
        width: 200rem;
        // width: 220rem;
        @media (min-aspect-ratio: 442/294) {
          width: 17.99vh;
          // width: 20.37vh;
        }
        @include not-desktop {
          width: 160rem;
        }
      }
    }
    // ko
    @include lang-ko {
      #{$strategy} {
        &__itm {
          &-inr {
            &-inr {
              padding: 86rem 30rem 70rem;
              @media (min-aspect-ratio: 442/294) {
                padding: 7.96vh 2.77vh 6.48vh;
              }
            }
            @include only-tablet {
              padding: 42rem 20rem 45rem;
            }
            @include only-mobile {
              padding: 50rem 20rem 50rem;
            }
          }
        }
      }
    }
  }
  // ko
  @include lang-ko {
    #{$sectionStr} {
      &__con {
        padding: 117rem 0 120rem;
        @media (min-aspect-ratio: 442/294) {
          padding: 10.83vh 0 11.11vh;
        }
        @include only-tablet {
          padding: 79rem 0 80rem;
        }
        @include only-mobile {
          padding: 57rem 0 60rem;
        }
      }
      &__inr {
        @include only-tablet {
          margin: 16rem 0 0;
        }
        @include only-mobile {
          margin: 20rem 0 0;
        }
      }
      &__tit {
        &-eyebrow {
          font-family: $grotesk-family;
          top: 5rem;
          @include only-tablet {
            top: 4rem;
          }
          @include only-mobile {
            top: 6rem;
          }
        }
        &-txt {
          font-family: $pretendard-family;
          font-weight: 700;
          font-size: 44rem;
          line-height: 1.23;
          letter-spacing: -0.2rem;
          text-indent: 280rem;
          @media (min-aspect-ratio: 442/294) {
            font-size: 3.87vh;
          }
          @include only-tablet {
            padding: 0;
            font-size: 28rem;
            line-height: 1.21;
            text-indent: 168rem;
          }
          @include only-mobile {
            font-size: 24rem;
            line-height: 1.33;
            letter-spacing: -0.5rem;
            text-indent: 118rem;
            word-break: break-all;
          }
        }
      }
    }
  }
}

// leadership
@keyframes img-height {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
.section-leadership {
  padding: 20rem 0;
  @include not-desktop {
    padding: 15rem 0;
  }
  $this: '.leadership';
  #{$this} {
    position: relative;
    width: 100%;
    margin: 0 auto;
    transition: background-color 0.6s;
    &__cont {
      padding: 80rem 232rem 85rem;
      @include only-tablet {
        padding: 63rem 30rem 72rem;
      }
      @include only-mobile {
        padding: 65rem 27rem 168rem 28rem;
      }
    }
    &__tit {
      position: absolute;
      top: 28rem;
      left: 232rem;
      font-weight: 500;
      font-size: 20rem;
      line-height: 1;
      letter-spacing: normal;
      text-transform: uppercase;
      @include only-tablet {
        top: 30rem;
        left: 30rem;
        font-size: 16rem;
        line-height: 0.75;
      }
      @include only-mobile {
        top: 24rem;
        left: 50%;
        font-size: 12rem;
        line-height: 1;
        letter-spacing: -0.1rem;
        transform: translateX(-50%);
      }
    }
    &__top {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      position: relative;
      @include only-mobile {
        flex-direction: column;
      }
    }
    &__bottom {
      margin-top: 44rem;
      @include only-tablet {
        margin-top: -55rem;
      }
      @include only-mobile {
        margin-top: 248rem;
      }
    }
    &__desc {
      position: absolute;
      bottom: 0;
      width: 100%;
      @include only-tablet {
      }
      // @include only-mobile {
      //   position: relative;
      //   bottom: auto;
      //   left: auto;
      //   width: 100%;
      //   height: 104rem;
      //   margin-top: 95rem;
      //   transform: none;
      // }
    }
    &__text {
      position: absolute;
      left: 0;
      width: 100%;
      bottom: -138rem;
      font-family: $fruit-family;
      font-weight: 500;
      font-size: 44rem;
      line-height: 1;
      letter-spacing: -0.4rem;
      text-align: center;
      white-space: nowrap;
      transition: opacity 0.6s;
      @include only-tablet {
        bottom: -8rem;
        font-size: 30rem;
        font-weight: 500;
        letter-spacing: -0.2rem;
      }
      @include only-mobile {
        bottom: -228rem;
        width: 100%;
        font-size: 22rem;
        line-height: 1;
        letter-spacing: -0.1rem;
        transform: none;
      }
    }
    &__thum {
      position: relative;
      &-inr {
        overflow: hidden;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        transition: all 0.6s;
      }
      &-1 {
        width: 277rem;
        height: 364rem;
        @include only-tablet {
          width: 180rem;
          height: 240rem;
        }
        @include only-mobile {
          width: 94rem;
          height: 124rem;
        }
        #{$this}__img {
          height: 364rem;
          @include only-tablet {
            height: 240rem;
          }
          @include only-mobile {
            height: 124rem;
          }
        }
      }
      &-2 {
        width: 306rem;
        height: 399rem;
        @include only-tablet {
          width: 193rem;
          height: 250rem;
        }
        @include only-mobile {
          width: 135rem;
          height: 177rem;
        }
        #{$this}__img {
          height: 399rem;
          @include only-tablet {
            height: 250rem;
          }
          @include only-mobile {
            height: 177rem;
          }
        }
      }
    }
    &__img {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
    &__people {
      position: absolute;
      bottom: 32rem;
      left: 50%;
      transform: translate(-50%, 0);
      @include only-tablet {
        bottom: 32rem;
      }
      @include only-mobile {
        bottom: 20rem;
      }
    }
    &__person {
      display: block;
      position: relative;
      width: 100%;
      &:nth-child(1) {
        margin-bottom: -6rem;
        text-align: right;
        transform: translateX(-50%);
        @include only-mobile {
          margin-bottom: -3rem;
        }
        #{$this}__job {
          bottom: 0;
          right: 130rem;
          @include only-tablet {
            right: 81rem;
            bottom: 3rem;
          }
        }
      }
      &:nth-child(2) {
        text-align: left;
        transform: translateX(50%);
        #{$this}__job {
          bottom: 3rem;
          left: calc(100% - 2rem);
          @include only-tablet {
            bottom: -3rem;
            left: 100%;
          }
        }
      }
    }
    &__job {
      display: block;
      position: absolute;
      font-size: 14rem;
      line-height: 1;
      text-transform: uppercase;
      white-space: nowrap;
      transition: color 0.6s;
      @include only-tablet {
        font-size: 12rem;
      }
      @include only-mobile {
        display: block;
        position: static;
        margin-top: 4rem;
        font-size: 10rem;
      }
    }
    &__name {
      display: block;
      font-size: 32rem;
      font-weight: 500;
      line-height: 0.94;
      letter-spacing: -0.4rem;
      text-transform: uppercase;
      transition: color 0.6s;
      @include only-tablet {
        font-size: 20rem;
        line-height: 1;
        letter-spacing: -0.3rem;
      }
      @include only-mobile {
        font-size: 20rem;
        line-height: 1;
        letter-spacing: -0.5rem;
      }
    }

    &--tab-1 {
      background-color: #e8dcc5;
      #{$this}__text {
        &:nth-child(1) {
          opacity: 1;
        }
        &:nth-child(2) {
          opacity: 0;
        }
      }
      #{$this}__thum-inr {
        &:nth-child(1) {
          z-index: 1;
          animation: img-height 0.6s ease-in-out forwards;
        }
        &:nth-child(2) {
          // height: 0%;
        }
      }
      #{$this}__person {
        &:nth-child(1) {
          #{$this}__name,
          #{$this}__job {
            color: #000;
          }
        }
        &:nth-child(2) {
          #{$this}__name,
          #{$this}__job {
            color: #fff;
          }
        }
      }
    }
    &--tab-2 {
      background-color: #c2d0c6;
      #{$this}__text {
        &:nth-child(1) {
          opacity: 0;
        }
        &:nth-child(2) {
          opacity: 1;
        }
      }
      #{$this}__thum-inr {
        &:nth-child(1) {
          // height: 0%;
        }
        &:nth-child(2) {
          z-index: 1;
          animation: img-height 0.6s ease-in-out forwards;
        }
      }
      #{$this}__person {
        &:nth-child(1) {
          #{$this}__name,
          #{$this}__job {
            color: #fff;
          }
        }
        &:nth-child(2) {
          #{$this}__name,
          #{$this}__job {
            color: #000;
          }
        }
      }
    }
  }

  // 국문
  @include lang-ko {
    #{$this} {
      &__cont {
        @include only-tablet {
          padding: 53rem 30rem 82rem;
        }
      }
      &__tit {
        font-family: $grotesk-family;
      }
      &__thum {
        &-1 {
          @include only-desktop {
            width: 249rem;
            height: 327rem;
          }
          #{$this}__img {
            @include only-desktop {
              height: 327rem;
            }
          }
        }
        &-2 {
          @include only-desktop {
            width: 279rem;
            height: 364rem;
          }
          #{$this}__img {
            @include only-desktop {
              height: 364rem;
            }
          }
        }
      }
      &__bottom {
        @include only-desktop {
          margin-top: 116rem;
        }
        @include only-tablet {
          // margin-top: -66rem;
        }
        @include only-mobile {
          // margin-top: 13rem;
        }
      }
      &__desc {
        @include only-mobile {
          // margin-top: 22rem;
          // height: 128rem;
        }
      }
      &__text {
        bottom: -135rem;
        font-weight: 700;
        font-size: 52rem;
        line-height: 1.15;
        letter-spacing: -0.5rem;
        @include only-tablet {
          bottom: -13rem;
          font-size: 36rem;
          line-height: 1.22;
          letter-spacing: -0.2rem;
        }
        @include only-mobile {
          // top: 50%;
          // bottom: auto;
          bottom: -224rem;
          font-weight: 700;
          font-size: 24rem;
          line-height: 1.33;
          letter-spacing: -0.2rem;
          transform: translateY(-50%);
        }
      }
      &__people {
        position: absolute;
        bottom: 56rem;
        left: 50%;
        transform: translate(-50%, 0);
        @include only-tablet {
          bottom: 27rem;
        }
        @include only-mobile {
          bottom: 20rem;
        }
      }
      &__person {
        &:nth-child(1) {
          @include only-desktop {
            margin-bottom: 0rem;
          }
          @include only-mobile {
            margin-bottom: -1rem;
          }
          #{$this}__job {
            left: auto;
            right: calc(100% + 7rem);
            @include only-desktop {
              bottom: 7rem;
            }
            @include only-tablet {
              right: calc(100% + 4rem);
            }
          }
        }
        &:nth-child(2) {
          #{$this}__job {
            left: calc(100% + 7rem);
            @include only-desktop {
              bottom: 5rem;
            }
            @include only-tablet {
              bottom: 3rem;
              left: calc(100% + 4rem);
            }
          }
        }
      }
      &__job {
        font-family: $grotesk-family;
        font-weight: 400;
      }
      &__name {
        font-weight: 500;
        font-size: 40rem;
        line-height: 1.2;
        letter-spacing: -1.25rem;
        @include only-tablet {
          font-size: 30rem;
          letter-spacing: -0.2rem;
        }
        @include only-mobile {
          font-size: 24rem;
          line-height: 1;
          letter-spacing: -0.2rem;
        }
      }
    }
  }
}

// news
.section-news {
  $sectionNews: &;
  padding: 105rem 232rem 120rem;
  background-color: #f9f9f9;
  @include only-tablet {
    padding: 65rem 30rem 80rem;
  }
  @include only-mobile {
    padding: 50rem 15rem 60rem;
  }
  $this: '.news';
  #{$this} {
    &__head {
      &-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include only-mobile {
          margin-bottom: 20rem;
        }
      }
      &-title {
        font-size: 20rem;
        font-weight: 500;
        line-height: 1;
        @include only-tablet {
          font-size: 16rem;
          line-height: 0.75;
        }
        @include only-mobile {
          font-size: 12rem;
          letter-spacing: -0.1rem;
        }
      }
      &-link {
        display: inline-block;
        margin-right: -17rem;
        padding: 17rem;
        font-size: 16rem;
        font-weight: 500;
        line-height: 1;
        letter-spacing: -0.1rem;
        @include only-tablet {
          margin-right: -18rem;
          padding: 18rem;
          font-size: 14rem;
        }
        @include only-mobile {
          margin-right: 0;
          font-size: 12rem;
        }
        &-wrap {
          &--mo {
            display: none;
          }
          @include only-mobile {
            margin-top: 40rem;
            padding-top: 15rem;
            border-top: 1px solid #000;
            text-align: center;
            &--mo {
              display: block;
            }
            &--pc {
              display: none;
            }
          }
        }
      }
      &-icon {
        width: 14rem;
        margin-left: 3rem;
        @include only-tablet {
          margin-left: 4rem;
        }
        @include only-mobile {
          width: 13rem;
          margin-left: 6rem;
        }
      }
    }
    &__lists {
      &-wrap {
        margin-right: -32rem;
        @include only-tablet {
          margin-right: -20rem;
        }
        @include only-mobile {
          padding-left: 15rem;
          margin: 0 -15rem;
        }
      }
      &-item {
        width: 33.333%;
        height: auto;
        padding-right: 32em;
        list-style: none;
        @include only-tablet {
          padding-right: 20em;
        }
        @include only-mobile {
          padding-right: 15rem;
          width: 95%;
        }
      }
      &-img {
        height: 266rem;
        @include only-tablet {
          height: 205rem;
        }
        @include only-mobile {
          height: 220rem;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &-title {
        min-height: 140rem;
        font-size: 24rem;
        font-weight: 500;
        line-height: 1.17;
        letter-spacing: -0.2rem;
        text-transform: uppercase;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @include only-tablet {
          min-height: 100rem;
          font-size: 20rem;
          line-height: 1;
          letter-spacing: -0.1rem;
        }
        @include only-mobile {
          min-height: 80rem;
          font-size: 16rem;
          line-height: 1;
        }
        &-wrap {
          padding-right: 40rem;
          margin-top: 24rem;
          @include only-mobile {
            margin-top: 20rem;
          }
        }
      }
      &-link {
        display: inline-block;
        position: relative;
        margin-top: 24rem;
        font-size: 16rem;
        font-weight: 500;
        line-height: 1;
        span {
          display: inline-block;
        }
        @include only-tablet {
          font-size: 14rem;
          line-height: 1.14;
        }
        @include only-mobile {
          margin-top: 16rem;
          font-size: 12rem;
          letter-spacing: -0.1rem;
        }
      }
    }
  }

  // 국문
  @include lang-ko {
    #{$this} {
      &__lists {
        &-title {
          min-height: 85rem;
          font-weight: bold;
          -webkit-line-clamp: 3;
          @include only-tablet {
            min-height: 72rem;
            line-height: 1.2;
            letter-spacing: -.2rem;
          }
          @include only-mobile {
            min-height: 60rem;        
            line-height: 1.25;
          }
        }
        &-link {
          @include only-mobile {
            margin-top: 36rem;
          }
        }
      }
    }
  }
}