$grotesk-family: 'abcmonumentgrotesk', 'pretendard', sans-serif;
$fruit-family: 'cardinalfruit', 'pretendard', sans-serif;
$pretendard-family: 'pretendard', 'abcmonumentgrotesk', sans-serif;

$desktopMax: 1728;
$desktopMin: 1025;
$tabletMax: 1024;
$tabletMin: 751;
$mobileMax: 750;
$mobileMin: 375;
