@mixin only-desktop {
  @media screen and (min-width:#{$desktopMin}px) {
    @content;
  }
}
@mixin only-tablet {
  @media screen and (min-width:#{$tabletMin}px) and (max-width:#{$tabletMax}px) {
    @content;
  }
}
@mixin only-mobile {
  @media screen and (max-width:#{$mobileMax}px) {
    @content;
  }
}
@mixin not-mobile {
  @media screen and (min-width:#{$tabletMin}px) {
    @content;
  }
}
@mixin not-desktop {
  @media screen and (max-width:#{$tabletMax}px) {
    @content;
  }
}
@mixin breakpoint($breakpoint, $standard: max) {
  @if ($standard == max) {
    @media screen and (max-width: #{$breakpoint}px) {
      @content;
    }
  } @else if ($standard == min) {
    @media screen and (min-width: #{$breakpoint}px) {
      @content;
    }
  }
}
@mixin clearfix {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

@mixin lang-ko {
  @at-root [lang='ko'] body {
    @content;
  }
}

@mixin ellipsis($line: 1) {
  @if ($line == 1) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  } @else {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: $line;

    -webkit-box-orient: vertical;
  }
}
