.header__logo-square {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.45s cubic-bezier(0.55, 0, 0.1, 1), visibility 0s 0.45s;
}
.header__logo.s-show .header__logo-square {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.45s cubic-bezier(0.55, 0, 0.1, 1), visibility 0s 0s;
}

.section-kv {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #c9c0b2;
}
@media screen and (max-width: 750px) {
  .section-kv {
    height: calc(var(--height) * 1px);
  }
}
.section-kv__vid {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}
.section-kv__vid--pc {
  height: 100%;
  object-fit: cover;
}
@media screen and (min-width: 751px) and (max-width: 1024px) and (orientation: portrait) {
  .section-kv__vid--pc {
    display: none;
  }
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-kv__vid--ta {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media screen and (min-width: 751px) and (max-width: 1024px) and (orientation: landscape) {
  .section-kv__vid--ta {
    display: none;
  }
}
@media screen and (max-width: 750px) {
  .section-kv__vid--mo {
    height: 100%;
    top: 0;
    left: 0;
    transform: translate(0, 0);
    object-fit: cover;
  }
}
.section-kv__txt-wrap {
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%) translateX(240rem);
  opacity: 0;
  transition: left 1.5s, opacity 2s;
}
.section-kv__txt-wrap.s-show {
  left: 50%;
  opacity: 1;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-kv__txt-wrap {
    transform: translate(-50%, -50%) translate(175rem, 0);
  }
}
@media screen and (min-width: 751px) and (max-width: 1024px) and (orientation: portrait) {
  .section-kv__txt-wrap {
    top: auto;
    bottom: 20%;
    left: 0;
    transform: none;
    width: 100%;
    padding: 0 50rem;
    transition: bottom 0.8s, opacity 2s;
  }
  .section-kv__txt-wrap.s-show {
    left: 0;
    bottom: 80rem;
  }
}
@media screen and (max-width: 750px) {
  .section-kv__txt-wrap {
    top: auto;
    left: 0;
    bottom: 20%;
    transform: none;
    width: 100%;
    padding: 0 15rem;
    transition: bottom 0.8s, opacity 2s;
  }
  .section-kv__txt-wrap.s-show {
    left: 0;
    bottom: 50rem;
  }
}
.section-kv__txt {
  font-weight: 500;
  font-size: 64rem;
  line-height: 0.94;
  letter-spacing: -1.5rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-kv__txt {
    font-size: 36rem;
    line-height: 0.94;
    letter-spacing: -0.5rem;
  }
}
@media screen and (min-width: 751px) and (max-width: 1024px) and (orientation: portrait) {
  .section-kv__txt {
    font-size: 64rem;
    line-height: 0.94;
    letter-spacing: -1rem;
  }
}
@media screen and (max-width: 750px) {
  .section-kv__txt {
    font-size: 32rem;
    line-height: 1;
    letter-spacing: -0.5rem;
  }
}
.section-kv__txt.c-text--start + .section-kv__subtxt {
  animation: ShowSubText 1.5s cubic-bezier(0.33, -0.11, 0.65, 0.93) 0.35s forwards;
}
.section-kv__subtxt {
  margin: 26rem 0 0;
  font-size: 24rem;
  line-height: 1;
  letter-spacing: -0.4rem;
}
@media screen and (max-width: 1024px) {
  .section-kv__subtxt {
    margin: 16rem 0 0;
  }
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-kv__subtxt {
    font-size: 16rem;
    line-height: 1;
    letter-spacing: -0.2rem;
  }
}
@media screen and (min-width: 751px) and (max-width: 1024px) and (orientation: portrait) {
  .section-kv__subtxt {
    margin: 30rem 0 0;
    font-size: 28rem;
    line-height: 1.14;
    letter-spacing: -0.3rem;
  }
}
@media screen and (max-width: 750px) {
  .section-kv__subtxt {
    font-size: 14rem;
    letter-spacing: -0.2rem;
  }
}
@media screen and (min-width: 1025px) {
  [lang=ko] body .section-kv__txt-wrap {
    transform: translate(-50%, -50%) translate(205rem, 0);
  }
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  [lang=ko] body .section-kv__txt-wrap {
    transform: translate(-50%, -50%) translate(170rem, 0);
  }
}
@media screen and (min-width: 751px) and (max-width: 1024px) and (orientation: portrait) {
  [lang=ko] body .section-kv__txt-wrap {
    top: auto;
    bottom: 80rem;
    left: 0;
    transform: none;
    width: 100%;
    padding: 0 50rem;
  }
}
[lang=ko] body .section-kv__txt {
  font-weight: 700;
  font-size: 76rem;
  line-height: 1.13;
  letter-spacing: -1rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  [lang=ko] body .section-kv__txt {
    font-size: 48rem;
    line-height: 1.17;
    letter-spacing: -0.3rem;
  }
}
@media screen and (min-width: 751px) and (max-width: 1024px) and (orientation: portrait) {
  [lang=ko] body .section-kv__txt {
    font-size: 72rem;
    letter-spacing: -0.5rem;
  }
}
@media screen and (max-width: 750px) {
  [lang=ko] body .section-kv__txt {
    font-size: 32rem;
    line-height: 1.13;
    letter-spacing: -0.3rem;
  }
}
[lang=ko] body .section-kv__subtxt {
  margin: 25rem 0 0;
  font-weight: 500;
  font-size: 28rem;
  line-height: 1.29;
  letter-spacing: -0.4rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  [lang=ko] body .section-kv__subtxt {
    margin: 15rem 0 0;
    font-size: 18rem;
    line-height: 1.33;
    letter-spacing: -0.2rem;
  }
}
@media screen and (min-width: 751px) and (max-width: 1024px) and (orientation: portrait) {
  [lang=ko] body .section-kv__subtxt {
    margin: 30rem 0 0;
    font-size: 26rem;
    line-height: 1.38;
    letter-spacing: -0.3rem;
  }
}
@media screen and (max-width: 750px) {
  [lang=ko] body .section-kv__subtxt {
    margin: 10rem 0 0;
    font-size: 14rem;
    line-height: 1.29;
    letter-spacing: -0.1rem;
  }
}

@keyframes ShowSubText {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.section-track {
  position: relative;
  height: 400vh;
}
@media screen and (min-width: 751px) {
  .section-track {
    --nth1-width: calc(190 / 1728 * var(--width) * 1px);
    --nth1-height: calc(70 / 1080 * 100vh);
    --nth2-width: calc(562 / 1728 * var(--width) * 1px);
    --nth2-height: calc(381 / 1080 * 100vh);
    --nth3-width: calc(1146 / 1728 * var(--width) * 1px);
    --nth3-height: calc(746 / 1080 * 100vh);
    --nth4-width: calc(var(--width) * 1px);
    --nth4-height: 100vh;
    z-index: 50;
  }
}
@media screen and (max-width: 750px) {
  .section-track {
    --nth1-width: calc(70 / 375 * var(--width) * 1px);
    --nth1-height: calc(70rem * 0.68571429);
    --nth2-width: calc(170 / 375 * var(--width) * 1px);
    --nth2-height: calc(170rem * 1.14117647);
    --nth3-width: calc(272 / 375 * var(--width) * 1px);
    --nth3-height: calc(272rem * 1.38970588);
    --nth4-width: calc(var(--width) * 1px);
    --nth4-height: calc(var(--width) * 1px * 1.49333333);
    height: auto;
  }
}
.section-track__cont {
  overflow: hidden;
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
}
@media screen and (max-width: 750px) {
  .section-track__cont {
    position: relative;
    height: auto;
  }
}
.section-track__inr {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #e8dcc5;
}
@media screen and (max-width: 750px) {
  .section-track__inr {
    position: relative;
    height: calc(var(--width) * 1px * 1.49333333);
    background: none;
  }
  .section-track--is-active .section-track__inr {
    height: calc(var(--width) * 1px * 1.49333333);
  }
}
.section-track__tit {
  font-family: "abcmonumentgrotesk", "pretendard", sans-serif;
}
.section-track__unit {
  display: inline-block;
  vertical-align: bottom;
}
.section-track__item {
  overflow: hidden;
  position: absolute;
  left: 0;
  bottom: 0;
  text-transform: uppercase;
}
.section-track__item:nth-child(1) {
  z-index: calc(4 - 1);
}
.section-track__item:nth-child(2) {
  z-index: calc(4 - 2);
}
.section-track__item:nth-child(3) {
  z-index: calc(4 - 3);
}
.section-track__item:nth-child(4) {
  z-index: calc(4 - 4);
}
.section-track__item:nth-child(1) .section-track__head {
  display: flex;
  align-items: center;
  width: calc(var(--nth1-width));
  height: calc(var(--nth1-height));
  padding: 0 0 0 20rem;
  background: #fff;
  opacity: 1;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-track__item:nth-child(1) .section-track__head {
    padding: 0 0 0 15rem;
  }
}
@media screen and (max-width: 750px) {
  .section-track__item:nth-child(1) .section-track__head {
    padding: 0 0 0 10rem;
  }
}
.section-track__item:nth-child(1) .section-track__tit {
  font-weight: 500;
  font-size: 20rem;
  letter-spacing: -0.2rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-track__item:nth-child(1) .section-track__tit {
    font-size: 16rem;
    letter-spacing: normal;
  }
}
@media screen and (max-width: 750px) {
  .section-track__item:nth-child(1) .section-track__tit {
    font-size: 12rem;
    letter-spacing: -0.3rem;
  }
}
.section-track__item:nth-child(2) {
  width: calc(var(--nth1-width) - var(--nth1-width) * min(var(--progress, 0), 0.3) * 3.3333333333 + var(--nth2-width) * min(var(--progress, 0), 0.3) * 3.3333333333);
  height: calc(var(--nth1-height) - var(--nth1-height) * min(var(--progress, 0), 0.3) * 3.3333333333 + var(--nth2-height) * min(var(--progress, 0), 0.3) * 3.3333333333);
  background: #fbf6ee;
}
@media screen and (min-width: 751px) {
  .section-track__item:nth-child(2) {
    width: calc(var(--nth1-width) - var(--nth1-width) * min(var(--progress, 0), 0.3) * 3.3333333333 + var(--nth2-width) * min(var(--progress, 0), 0.3) * 3.3333333333);
    height: calc(var(--nth1-height) - var(--nth1-height) * min(var(--progress, 0), 0.3) * 3.3333333333 + var(--nth2-height) * min(var(--progress, 0), 0.3) * 3.3333333333);
  }
}
@media screen and (max-width: 750px) {
  .section-track__item:nth-child(2) {
    width: var(--nth2-width);
    height: var(--nth2-height);
  }
}
@media screen and (min-width: 751px) {
  .section-track__item:nth-child(2) .section-track__head {
    opacity: calc(max(var(--progress, 0.15) - 0.15, 0) * 6.6666666667);
  }
}
@media screen and (max-width: 750px) {
  .section-track__item:nth-child(2) .section-track__head {
    opacity: 1;
  }
}
.section-track__item:nth-child(2) .section-track__tit {
  font-weight: 500;
  font-size: 32rem;
  line-height: 1.06;
  letter-spacing: -0.5rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-track__item:nth-child(2) .section-track__tit {
    font-size: 20rem;
    line-height: 1;
    letter-spacing: -0.2rem;
  }
}
@media screen and (max-width: 750px) {
  .section-track__item:nth-child(2) .section-track__tit {
    font-size: 12rem;
    line-height: 1;
    letter-spacing: -0.3rem;
  }
}
.section-track__item:nth-child(2) .section-track__value {
  font-weight: 500;
  font-size: 60rem;
  line-height: 1;
  letter-spacing: -2.5rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-track__item:nth-child(2) .section-track__value {
    font-size: 30rem;
    line-height: 1;
    letter-spacing: -0.5rem;
  }
}
@media screen and (max-width: 750px) {
  .section-track__item:nth-child(2) .section-track__value {
    font-size: 20rem;
    line-height: 1;
    letter-spacing: normal;
  }
}
.section-track__item:nth-child(2) .section-track__desc {
  margin-top: 47rem;
  font-size: 20rem;
  line-height: 1.1;
  letter-spacing: -0.3rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-track__item:nth-child(2) .section-track__desc {
    margin-top: 20rem;
    font-size: 14rem;
    line-height: 1;
    letter-spacing: -0.1rem;
  }
}
@media screen and (max-width: 750px) {
  .section-track__item:nth-child(2) .section-track__desc {
    margin-top: 22rem;
    font-size: 10rem;
    line-height: 1;
    letter-spacing: -0.2rem;
  }
}
.section-track__item:nth-child(3) {
  width: calc(var(--nth1-width) - var(--nth1-width) * min(var(--progress, 0), 0.3) * 3.3333333333 + var(--nth2-width) * min(var(--progress, 0), 0.3) * 3.3333333333 - var(--nth2-width) * min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * 3.3333333333 + var(--nth3-width) * min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * 3.3333333333);
  height: calc(var(--nth1-height) - var(--nth1-height) * min(var(--progress, 0), 0.3) * 3.3333333333 + var(--nth2-height) * min(var(--progress, 0), 0.3) * 3.3333333333 - var(--nth2-height) * min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * 3.3333333333 + var(--nth3-height) * min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * 3.3333333333);
  background: #f3eade;
}
@media screen and (min-width: 751px) {
  .section-track__item:nth-child(3) {
    width: calc(var(--nth1-width) - var(--nth1-width) * min(var(--progress, 0), 0.3) * 3.3333333333 + var(--nth2-width) * min(var(--progress, 0), 0.3) * 3.3333333333 - var(--nth2-width) * min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * 3.3333333333 + var(--nth3-width) * min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * 3.3333333333);
    height: calc(var(--nth1-height) - var(--nth1-height) * min(var(--progress, 0), 0.3) * 3.3333333333 + var(--nth2-height) * min(var(--progress, 0), 0.3) * 3.3333333333 - var(--nth2-height) * min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * 3.3333333333 + var(--nth3-height) * min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * 3.3333333333);
  }
}
@media screen and (max-width: 750px) {
  .section-track__item:nth-child(3) {
    width: var(--nth3-width);
    height: var(--nth3-height);
  }
}
@media screen and (min-width: 751px) {
  .section-track__item:nth-child(3) .section-track__head {
    opacity: calc(max(var(--progress, 0.45) - 0.45, 0) * 6.6666666667);
  }
}
@media screen and (max-width: 750px) {
  .section-track__item:nth-child(3) .section-track__head {
    opacity: 1;
  }
}
.section-track__item:nth-child(3) .section-track__tit {
  font-weight: 500;
  font-size: 48rem;
  line-height: 1.04;
  letter-spacing: -1.5rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-track__item:nth-child(3) .section-track__tit {
    font-size: 28rem;
    line-height: 1;
    letter-spacing: -0.2rem;
  }
}
@media screen and (max-width: 750px) {
  .section-track__item:nth-child(3) .section-track__tit {
    font-size: 18rem;
    line-height: 1;
    letter-spacing: -0.5rem;
  }
}
.section-track__item:nth-child(3) .section-track__value {
  font-weight: 500;
  font-size: 88rem;
  line-height: 0.91;
  letter-spacing: -3.5rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-track__item:nth-child(3) .section-track__value {
    font-size: 42rem;
    line-height: 0.95;
    letter-spacing: -1rem;
  }
}
@media screen and (max-width: 750px) {
  .section-track__item:nth-child(3) .section-track__value {
    font-size: 32rem;
    line-height: 0.72;
    letter-spacing: -1rem;
  }
}
.section-track__item:nth-child(3) .section-track__desc {
  font-size: 24rem;
  line-height: 1.08;
  letter-spacing: -0.4rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-track__item:nth-child(3) .section-track__desc {
    font-size: 16rem;
    line-height: 1;
    letter-spacing: -0.1rem;
  }
}
@media screen and (max-width: 750px) {
  .section-track__item:nth-child(3) .section-track__desc {
    font-size: 12rem;
    line-height: 1;
    letter-spacing: -0.1rem;
  }
}
.section-track__item:nth-child(4) {
  width: calc(var(--nth1-width) - var(--nth1-width) * min(var(--progress, 0), 0.3) * 3.3333333333 + var(--nth2-width) * min(var(--progress, 0), 0.3) * 3.3333333333 - var(--nth2-width) * min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * 3.3333333333 + var(--nth3-width) * min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * 3.3333333333 - var(--nth3-width) * min(max(var(--progress, 0), 0.3) - 0.6, 0.3) * 3.3333333333 + var(--nth4-width) * min(max(var(--progress, 0), 0.3) - 0.6, 0.3) * 3.3333333333);
  height: calc(var(--nth1-height) - var(--nth1-height) * min(var(--progress, 0), 0.3) * 3.3333333333 + var(--nth2-height) * min(var(--progress, 0), 0.3) * 3.3333333333 - var(--nth2-height) * min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * 3.3333333333 + var(--nth3-height) * min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * 3.3333333333 - var(--nth3-height) * min(max(var(--progress, 0), 0.3) - 0.6, 0.3) * 3.3333333333 + var(--nth4-height) * min(max(var(--progress, 0), 0.3) - 0.6, 0.3) * 3.3333333333);
  background: #e8dcc5;
}
@media screen and (min-width: 751px) {
  .section-track__item:nth-child(4) {
    width: calc(var(--nth1-width) - var(--nth1-width) * min(var(--progress, 0), 0.3) * 3.3333333333 + var(--nth2-width) * min(var(--progress, 0), 0.3) * 3.3333333333 - var(--nth2-width) * min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * 3.3333333333 + var(--nth3-width) * min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * 3.3333333333 - var(--nth3-width) * min(max(var(--progress, 0), 0.3) - 0.6, 0.3) * 3.3333333333 + var(--nth4-width) * min(max(var(--progress, 0), 0.3) - 0.6, 0.3) * 3.3333333333);
    height: calc(var(--nth1-height) - var(--nth1-height) * min(var(--progress, 0), 0.3) * 3.3333333333 + var(--nth2-height) * min(var(--progress, 0), 0.3) * 3.3333333333 - var(--nth2-height) * min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * 3.3333333333 + var(--nth3-height) * min(max(var(--progress, 0), 0.3) - 0.3, 0.3) * 3.3333333333 - var(--nth3-height) * min(max(var(--progress, 0), 0.3) - 0.6, 0.3) * 3.3333333333 + var(--nth4-height) * min(max(var(--progress, 0), 0.3) - 0.6, 0.3) * 3.3333333333);
  }
}
@media screen and (max-width: 750px) {
  .section-track__item:nth-child(4) {
    width: var(--nth4-width);
    height: var(--nth4-height);
  }
}
@media screen and (min-width: 751px) {
  .section-track__item:nth-child(4) .section-track__head {
    opacity: calc(max(var(--progress, 0.75) - 0.75, 0) * 6.6666666667);
  }
}
@media screen and (max-width: 750px) {
  .section-track__item:nth-child(4) .section-track__head {
    opacity: 1;
  }
}
.section-track__item:nth-child(4) .section-track__tit {
  font-weight: 500;
  font-size: 80rem;
  line-height: 1;
  letter-spacing: -3.5rem;
  text-transform: uppercase;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-track__item:nth-child(4) .section-track__tit {
    font-size: 32rem;
    line-height: 1.06;
    letter-spacing: -0.3rem;
  }
}
@media screen and (max-width: 750px) {
  .section-track__item:nth-child(4) .section-track__tit {
    font-size: 24rem;
    line-height: 1;
    letter-spacing: -0.5rem;
  }
}
.section-track__item:nth-child(4) .section-track__value {
  font-weight: 500;
  font-size: 104rem;
  line-height: 1;
  letter-spacing: -3.5rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-track__item:nth-child(4) .section-track__value {
    font-size: 46rem;
    line-height: 1;
    letter-spacing: -1rem;
  }
}
@media screen and (max-width: 750px) {
  .section-track__item:nth-child(4) .section-track__value {
    font-size: 40rem;
    line-height: 1;
    letter-spacing: -1rem;
  }
}
.section-track__item:nth-child(4) .section-track__desc {
  margin-top: 100rem;
  font-size: 32rem;
  line-height: 1;
  letter-spacing: -0.5rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-track__item:nth-child(4) .section-track__desc {
    margin-top: 40rem;
    font-size: 20rem;
    line-height: 1;
    letter-spacing: -0.3rem;
  }
}
@media screen and (max-width: 750px) {
  .section-track__item:nth-child(4) .section-track__desc {
    margin-top: 29rem;
    font-size: 14rem;
    line-height: 1.14;
    letter-spacing: -0.1rem;
  }
}
.section-track__item:nth-child(4) .section-track__unit {
  margin-bottom: 21rem;
  font-weight: 700;
  font-size: 48rem;
  line-height: 1;
  letter-spacing: -1.5rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-track__item:nth-child(4) .section-track__unit {
    margin-bottom: 8rem;
    font-size: 24rem;
    line-height: 1;
    letter-spacing: -0.5rem;
  }
}
@media screen and (max-width: 750px) {
  .section-track__item:nth-child(4) .section-track__unit {
    margin-bottom: 8rem;
    font-size: 24rem;
    line-height: 1;
    letter-spacing: -0.5rem;
  }
}
.section-track__head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24rem 20rem;
  opacity: 0;
}
@media screen and (max-width: 750px) {
  .section-track__head {
    padding: 14rem 10rem;
  }
}
.section-track__info {
  text-align: right;
}
[lang=ko] body .section-track__item:nth-child(2) .section-track__tit {
  font-weight: 700;
}
@media screen and (max-width: 750px) {
  [lang=ko] body .section-track__item:nth-child(2) .section-track__tit {
    font-size: 12rem;
  }
}
[lang=ko] body .section-track__item:nth-child(2) .section-track__value {
  font-family: "abcmonumentgrotesk", "pretendard", sans-serif;
}
[lang=ko] body .section-track__item:nth-child(2) .section-track__desc {
  font-weight: 500;
  line-height: normal;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  [lang=ko] body .section-track__item:nth-child(2) .section-track__desc {
    margin-top: 26rem;
    font-size: 16rem;
    line-height: 1;
  }
}
@media screen and (max-width: 750px) {
  [lang=ko] body .section-track__item:nth-child(2) .section-track__desc {
    margin-top: 21rem;
    font-size: 12rem;
    line-height: 0.83;
  }
}
[lang=ko] body .section-track__item:nth-child(2) .section-track__unit {
  margin-bottom: 7rem;
  margin-left: 4rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  [lang=ko] body .section-track__item:nth-child(2) .section-track__unit {
    margin-bottom: 3rem;
    margin-left: 2rem;
  }
}
@media screen and (max-width: 750px) {
  [lang=ko] body .section-track__item:nth-child(2) .section-track__unit {
    margin-bottom: 2rem;
    margin-left: 1rem;
  }
}
[lang=ko] body .section-track__item:nth-child(3) .section-track__tit {
  font-weight: 700;
  line-height: 1.17;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  [lang=ko] body .section-track__item:nth-child(3) .section-track__tit {
    font-size: 24rem;
  }
}
@media screen and (max-width: 750px) {
  [lang=ko] body .section-track__item:nth-child(3) .section-track__tit {
    font-size: 16rem;
    line-height: 1.13;
  }
}
[lang=ko] body .section-track__item:nth-child(3) .section-track__value {
  font-family: "abcmonumentgrotesk", "pretendard", sans-serif;
}
@media screen and (max-width: 750px) {
  [lang=ko] body .section-track__item:nth-child(3) .section-track__value {
    font-size: 28rem;
    line-height: 0.82;
  }
}
[lang=ko] body .section-track__item:nth-child(3) .section-track__desc {
  font-weight: 500;
  font-size: 32rem;
  line-height: 1.16;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  [lang=ko] body .section-track__item:nth-child(3) .section-track__desc {
    font-size: 20rem;
    line-height: 1.1;
  }
}
@media screen and (max-width: 750px) {
  [lang=ko] body .section-track__item:nth-child(3) .section-track__desc {
    margin-top: 36rem;
    font-size: 14rem;
    line-height: 1.14;
  }
}
[lang=ko] body .section-track__item:nth-child(3) .section-track__unit {
  margin: 0 0 9rem 8rem;
  font-weight: 700;
  font-size: 40rem;
  line-height: 1.13;
  letter-spacing: normal;
}
@media screen and (max-width: 1024px) {
  [lang=ko] body .section-track__item:nth-child(3) .section-track__unit {
    line-height: 1;
    letter-spacing: -0.5rem;
  }
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  [lang=ko] body .section-track__item:nth-child(3) .section-track__unit {
    margin: 0 0 3rem 5rem;
    font-size: 24rem;
  }
}
@media screen and (max-width: 750px) {
  [lang=ko] body .section-track__item:nth-child(3) .section-track__unit {
    margin: 0 0 0 3rem;
    font-size: 20rem;
  }
}
[lang=ko] body .section-track__item:nth-child(3) .section-track__unit:first-child {
  position: relative;
  top: -43rem;
  margin: 0 10rem 0 0;
  font-weight: normal;
  font-size: 24rem;
  line-height: 0.83;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  [lang=ko] body .section-track__item:nth-child(3) .section-track__unit:first-child {
    top: -20rem;
    margin: 0 4rem 0 0;
    font-size: 16rem;
    line-height: 1;
    letter-spacing: -0.2rem;
  }
}
@media screen and (max-width: 750px) {
  [lang=ko] body .section-track__item:nth-child(3) .section-track__unit:first-child {
    top: -8rem;
    margin: 0 3rem 0 0;
    font-size: 12rem;
    line-height: 1;
    letter-spacing: -0.3rem;
  }
}
[lang=ko] body .section-track__item:nth-child(4) .section-track__tit {
  font-weight: 500;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  [lang=ko] body .section-track__item:nth-child(4) .section-track__tit {
    font-size: 20rem;
    line-height: 1.1;
  }
}
[lang=ko] body .section-track__item:nth-child(4) .section-track__value {
  font-family: "abcmonumentgrotesk", "pretendard", sans-serif;
}
@media screen and (max-width: 750px) {
  [lang=ko] body .section-track__item:nth-child(4) .section-track__value {
    margin-top: -4rem;
    font-size: 36rem;
    line-height: 1.11;
    letter-spacing: -0.9rem;
  }
}
[lang=ko] body .section-track__item:nth-child(4) .section-track__unit {
  font-weight: 700;
  margin-bottom: 17rem;
  margin-left: 6rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  [lang=ko] body .section-track__item:nth-child(4) .section-track__unit {
    margin-bottom: 5rem;
    margin-left: 4rem;
  }
}
@media screen and (max-width: 750px) {
  [lang=ko] body .section-track__item:nth-child(4) .section-track__unit {
    margin-bottom: 7rem;
    margin-left: 2rem;
  }
}
[lang=ko] body .section-track__item:nth-child(4) .section-track__unit:first-child {
  position: relative;
  top: -48rem;
  margin: 0 13rem 0 0;
  font-weight: normal;
  font-size: 28rem;
  line-height: 1.29;
}
@media screen and (max-width: 1024px) {
  [lang=ko] body .section-track__item:nth-child(4) .section-track__unit:first-child {
    line-height: 1;
    letter-spacing: -0.3rem;
  }
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  [lang=ko] body .section-track__item:nth-child(4) .section-track__unit:first-child {
    top: -22rem;
    margin: 0 5rem 0 0;
    font-size: 16rem;
  }
}
@media screen and (max-width: 750px) {
  [lang=ko] body .section-track__item:nth-child(4) .section-track__unit:first-child {
    top: -19rem;
    margin: 0 6rem 0 0;
    font-size: 12rem;
  }
}
[lang=ko] body .section-track__item:nth-child(4) .section-track__desc {
  font-weight: 500;
  font-size: 40rem;
  line-height: 1.13;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  [lang=ko] body .section-track__item:nth-child(4) .section-track__desc {
    font-size: 22rem;
    line-height: 1.18;
    letter-spacing: normal;
  }
}
@media screen and (max-width: 750px) {
  [lang=ko] body .section-track__item:nth-child(4) .section-track__desc {
    margin-top: 23rem;
    font-size: 16rem;
    line-height: 1.13;
  }
}
@media screen and (max-width: 750px) {
  [lang=ko] body .section-track__head {
    padding: 14rem 13rem;
  }
}

.section-str {
  height: 100vh;
}
@media screen and (max-width: 1024px) {
  .section-str {
    height: auto;
  }
}
.section-str__con {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 150rem 0;
}
@media (min-aspect-ratio: 442/294) {
  .section-str__con {
    padding: 14.17vh 0;
  }
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-str__con {
    padding: 80rem 0;
  }
}
@media screen and (min-width: 751px) and (max-width: 1024px) and (orientation: landscape) {
  .section-str__con {
    padding: 7.4vh 0;
  }
}
@media screen and (max-width: 750px) {
  .section-str__con {
    padding: 60rem 0;
  }
}
.section-str__tit {
  position: relative;
  margin: 0 232rem;
}
@media screen and (max-width: 1024px) {
  .section-str__tit {
    margin: 0 15rem;
  }
}
.section-str__tit-eyebrow {
  position: absolute;
  top: 3rem;
  left: 0;
  font-weight: 500;
  font-size: 20rem;
  line-height: 1;
  letter-spacing: 0.5rem;
}
@media (min-aspect-ratio: 442/294) {
  .section-str__tit-eyebrow {
    font-size: 1.85vh;
  }
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-str__tit-eyebrow {
    top: 2rem;
    font-size: 14rem;
    line-height: 1;
    letter-spacing: -0.1rem;
  }
}
@media screen and (max-width: 750px) {
  .section-str__tit-eyebrow {
    font-size: 12rem;
    line-height: 1;
    letter-spacing: -0.1rem;
  }
}
.section-str__tit-txt {
  font-family: "cardinalfruit", "pretendard", sans-serif;
  font-weight: 500;
  font-size: 52rem;
  line-height: 1;
  letter-spacing: -0.3rem;
  text-indent: 158rem;
}
@media (min-aspect-ratio: 442/294) {
  .section-str__tit-txt {
    font-size: 4.67vh;
  }
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-str__tit-txt {
    padding: 0 50rem 0 0;
    font-size: 26rem;
    line-height: 1.08;
    letter-spacing: -0.2rem;
    text-indent: 168rem;
  }
}
@media screen and (max-width: 750px) {
  .section-str__tit-txt {
    font-size: 26rem;
    line-height: 1;
    letter-spacing: -0.1rem;
    text-indent: 110rem;
  }
}
.section-str__tit-top {
  display: inline-block;
  margin-bottom: 4rem;
  font-size: 52rem;
}
@media (min-aspect-ratio: 442/294) {
  .section-str__tit-top {
    font-size: 4.67vh;
  }
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-str__tit-top {
    display: inline;
    font-size: 26rem;
    line-height: 1.08;
    letter-spacing: -0.2rem;
  }
}
@media screen and (max-width: 750px) {
  .section-str__tit-top {
    display: inline;
    font-size: 26rem;
    line-height: 1;
    letter-spacing: -0.1rem;
  }
}
.section-str__inr {
  height: 100%;
  margin: 49rem 0 0;
}
@media (min-aspect-ratio: 442/294) {
  .section-str__inr {
    margin: 4.63vh 0 0;
  }
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-str__inr {
    margin: 20rem 0 0;
  }
}
@media screen and (max-width: 750px) {
  .section-str__inr {
    margin: 20rem 0 0;
  }
}
.section-str__list {
  height: 100%;
  padding: 0 232rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-str__list {
    padding: 0 15rem;
  }
}
@media screen and (max-width: 750px) {
  .section-str__list {
    padding: 0 15rem;
  }
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .c-strategy__itm {
    height: 432rem;
  }
}
@media screen and (max-width: 750px) {
  .c-strategy__itm {
    height: 420rem;
  }
}
@media screen and (max-width: 750px) {
  .c-strategy__itm + .c-strategy__itm {
    margin: 15rem 0 0;
  }
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .c-strategy__itm-inr {
    padding: 45rem 25rem 47rem;
  }
}
@media screen and (max-width: 750px) {
  .c-strategy__itm-inr {
    padding: 50rem 30rem 40rem;
  }
}
.c-strategy__itm-img-wrap {
  width: 200rem;
}
@media (min-aspect-ratio: 442/294) {
  .c-strategy__itm-img-wrap {
    width: 17.99vh;
  }
}
@media screen and (max-width: 1024px) {
  .c-strategy__itm-img-wrap {
    width: 160rem;
  }
}
[lang=ko] body .c-strategy__itm-inr-inr {
  padding: 86rem 30rem 70rem;
}
@media (min-aspect-ratio: 442/294) {
  [lang=ko] body .c-strategy__itm-inr-inr {
    padding: 7.96vh 2.77vh 6.48vh;
  }
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  [lang=ko] body .c-strategy__itm-inr {
    padding: 42rem 20rem 45rem;
  }
}
@media screen and (max-width: 750px) {
  [lang=ko] body .c-strategy__itm-inr {
    padding: 50rem 20rem 50rem;
  }
}

[lang=ko] body .section-str__con {
  padding: 117rem 0 120rem;
}
@media (min-aspect-ratio: 442/294) {
  [lang=ko] body .section-str__con {
    padding: 10.83vh 0 11.11vh;
  }
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  [lang=ko] body .section-str__con {
    padding: 79rem 0 80rem;
  }
}
@media screen and (max-width: 750px) {
  [lang=ko] body .section-str__con {
    padding: 57rem 0 60rem;
  }
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  [lang=ko] body .section-str__inr {
    margin: 16rem 0 0;
  }
}
@media screen and (max-width: 750px) {
  [lang=ko] body .section-str__inr {
    margin: 20rem 0 0;
  }
}
[lang=ko] body .section-str__tit-eyebrow {
  font-family: "abcmonumentgrotesk", "pretendard", sans-serif;
  top: 5rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  [lang=ko] body .section-str__tit-eyebrow {
    top: 4rem;
  }
}
@media screen and (max-width: 750px) {
  [lang=ko] body .section-str__tit-eyebrow {
    top: 6rem;
  }
}
[lang=ko] body .section-str__tit-txt {
  font-family: "pretendard", "abcmonumentgrotesk", sans-serif;
  font-weight: 700;
  font-size: 44rem;
  line-height: 1.23;
  letter-spacing: -0.2rem;
  text-indent: 280rem;
}
@media (min-aspect-ratio: 442/294) {
  [lang=ko] body .section-str__tit-txt {
    font-size: 3.87vh;
  }
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  [lang=ko] body .section-str__tit-txt {
    padding: 0;
    font-size: 28rem;
    line-height: 1.21;
    text-indent: 168rem;
  }
}
@media screen and (max-width: 750px) {
  [lang=ko] body .section-str__tit-txt {
    font-size: 24rem;
    line-height: 1.33;
    letter-spacing: -0.5rem;
    text-indent: 118rem;
    word-break: break-all;
  }
}

@keyframes img-height {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
.section-leadership {
  padding: 20rem 0;
}
@media screen and (max-width: 1024px) {
  .section-leadership {
    padding: 15rem 0;
  }
}
.section-leadership .leadership {
  position: relative;
  width: 100%;
  margin: 0 auto;
  transition: background-color 0.6s;
}
.section-leadership .leadership__cont {
  padding: 80rem 232rem 85rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-leadership .leadership__cont {
    padding: 63rem 30rem 72rem;
  }
}
@media screen and (max-width: 750px) {
  .section-leadership .leadership__cont {
    padding: 65rem 27rem 168rem 28rem;
  }
}
.section-leadership .leadership__tit {
  position: absolute;
  top: 28rem;
  left: 232rem;
  font-weight: 500;
  font-size: 20rem;
  line-height: 1;
  letter-spacing: normal;
  text-transform: uppercase;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-leadership .leadership__tit {
    top: 30rem;
    left: 30rem;
    font-size: 16rem;
    line-height: 0.75;
  }
}
@media screen and (max-width: 750px) {
  .section-leadership .leadership__tit {
    top: 24rem;
    left: 50%;
    font-size: 12rem;
    line-height: 1;
    letter-spacing: -0.1rem;
    transform: translateX(-50%);
  }
}
.section-leadership .leadership__top {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
}
@media screen and (max-width: 750px) {
  .section-leadership .leadership__top {
    flex-direction: column;
  }
}
.section-leadership .leadership__bottom {
  margin-top: 44rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-leadership .leadership__bottom {
    margin-top: -55rem;
  }
}
@media screen and (max-width: 750px) {
  .section-leadership .leadership__bottom {
    margin-top: 248rem;
  }
}
.section-leadership .leadership__desc {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.section-leadership .leadership__text {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: -138rem;
  font-family: "cardinalfruit", "pretendard", sans-serif;
  font-weight: 500;
  font-size: 44rem;
  line-height: 1;
  letter-spacing: -0.4rem;
  text-align: center;
  white-space: nowrap;
  transition: opacity 0.6s;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-leadership .leadership__text {
    bottom: -8rem;
    font-size: 30rem;
    font-weight: 500;
    letter-spacing: -0.2rem;
  }
}
@media screen and (max-width: 750px) {
  .section-leadership .leadership__text {
    bottom: -228rem;
    width: 100%;
    font-size: 22rem;
    line-height: 1;
    letter-spacing: -0.1rem;
    transform: none;
  }
}
.section-leadership .leadership__thum {
  position: relative;
}
.section-leadership .leadership__thum-inr {
  overflow: hidden;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transition: all 0.6s;
}
.section-leadership .leadership__thum-1 {
  width: 277rem;
  height: 364rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-leadership .leadership__thum-1 {
    width: 180rem;
    height: 240rem;
  }
}
@media screen and (max-width: 750px) {
  .section-leadership .leadership__thum-1 {
    width: 94rem;
    height: 124rem;
  }
}
.section-leadership .leadership__thum-1 .leadership__img {
  height: 364rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-leadership .leadership__thum-1 .leadership__img {
    height: 240rem;
  }
}
@media screen and (max-width: 750px) {
  .section-leadership .leadership__thum-1 .leadership__img {
    height: 124rem;
  }
}
.section-leadership .leadership__thum-2 {
  width: 306rem;
  height: 399rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-leadership .leadership__thum-2 {
    width: 193rem;
    height: 250rem;
  }
}
@media screen and (max-width: 750px) {
  .section-leadership .leadership__thum-2 {
    width: 135rem;
    height: 177rem;
  }
}
.section-leadership .leadership__thum-2 .leadership__img {
  height: 399rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-leadership .leadership__thum-2 .leadership__img {
    height: 250rem;
  }
}
@media screen and (max-width: 750px) {
  .section-leadership .leadership__thum-2 .leadership__img {
    height: 177rem;
  }
}
.section-leadership .leadership__img {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.section-leadership .leadership__people {
  position: absolute;
  bottom: 32rem;
  left: 50%;
  transform: translate(-50%, 0);
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-leadership .leadership__people {
    bottom: 32rem;
  }
}
@media screen and (max-width: 750px) {
  .section-leadership .leadership__people {
    bottom: 20rem;
  }
}
.section-leadership .leadership__person {
  display: block;
  position: relative;
  width: 100%;
}
.section-leadership .leadership__person:nth-child(1) {
  margin-bottom: -6rem;
  text-align: right;
  transform: translateX(-50%);
}
@media screen and (max-width: 750px) {
  .section-leadership .leadership__person:nth-child(1) {
    margin-bottom: -3rem;
  }
}
.section-leadership .leadership__person:nth-child(1) .leadership__job {
  bottom: 0;
  right: 130rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-leadership .leadership__person:nth-child(1) .leadership__job {
    right: 81rem;
    bottom: 3rem;
  }
}
.section-leadership .leadership__person:nth-child(2) {
  text-align: left;
  transform: translateX(50%);
}
.section-leadership .leadership__person:nth-child(2) .leadership__job {
  bottom: 3rem;
  left: calc(100% - 2rem);
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-leadership .leadership__person:nth-child(2) .leadership__job {
    bottom: -3rem;
    left: 100%;
  }
}
.section-leadership .leadership__job {
  display: block;
  position: absolute;
  font-size: 14rem;
  line-height: 1;
  text-transform: uppercase;
  white-space: nowrap;
  transition: color 0.6s;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-leadership .leadership__job {
    font-size: 12rem;
  }
}
@media screen and (max-width: 750px) {
  .section-leadership .leadership__job {
    display: block;
    position: static;
    margin-top: 4rem;
    font-size: 10rem;
  }
}
.section-leadership .leadership__name {
  display: block;
  font-size: 32rem;
  font-weight: 500;
  line-height: 0.94;
  letter-spacing: -0.4rem;
  text-transform: uppercase;
  transition: color 0.6s;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-leadership .leadership__name {
    font-size: 20rem;
    line-height: 1;
    letter-spacing: -0.3rem;
  }
}
@media screen and (max-width: 750px) {
  .section-leadership .leadership__name {
    font-size: 20rem;
    line-height: 1;
    letter-spacing: -0.5rem;
  }
}
.section-leadership .leadership--tab-1 {
  background-color: #e8dcc5;
}
.section-leadership .leadership--tab-1 .leadership__text:nth-child(1) {
  opacity: 1;
}
.section-leadership .leadership--tab-1 .leadership__text:nth-child(2) {
  opacity: 0;
}
.section-leadership .leadership--tab-1 .leadership__thum-inr:nth-child(1) {
  z-index: 1;
  animation: img-height 0.6s ease-in-out forwards;
}
.section-leadership .leadership--tab-1 .leadership__person:nth-child(1) .leadership__name,
.section-leadership .leadership--tab-1 .leadership__person:nth-child(1) .leadership__job {
  color: #000;
}
.section-leadership .leadership--tab-1 .leadership__person:nth-child(2) .leadership__name,
.section-leadership .leadership--tab-1 .leadership__person:nth-child(2) .leadership__job {
  color: #fff;
}
.section-leadership .leadership--tab-2 {
  background-color: #c2d0c6;
}
.section-leadership .leadership--tab-2 .leadership__text:nth-child(1) {
  opacity: 0;
}
.section-leadership .leadership--tab-2 .leadership__text:nth-child(2) {
  opacity: 1;
}
.section-leadership .leadership--tab-2 .leadership__thum-inr:nth-child(2) {
  z-index: 1;
  animation: img-height 0.6s ease-in-out forwards;
}
.section-leadership .leadership--tab-2 .leadership__person:nth-child(1) .leadership__name,
.section-leadership .leadership--tab-2 .leadership__person:nth-child(1) .leadership__job {
  color: #fff;
}
.section-leadership .leadership--tab-2 .leadership__person:nth-child(2) .leadership__name,
.section-leadership .leadership--tab-2 .leadership__person:nth-child(2) .leadership__job {
  color: #000;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  [lang=ko] body .leadership__cont {
    padding: 53rem 30rem 82rem;
  }
}
[lang=ko] body .leadership__tit {
  font-family: "abcmonumentgrotesk", "pretendard", sans-serif;
}
@media screen and (min-width: 1025px) {
  [lang=ko] body .leadership__thum-1 {
    width: 249rem;
    height: 327rem;
  }
}
@media screen and (min-width: 1025px) {
  [lang=ko] body .leadership__thum-1 .leadership__img {
    height: 327rem;
  }
}
@media screen and (min-width: 1025px) {
  [lang=ko] body .leadership__thum-2 {
    width: 279rem;
    height: 364rem;
  }
}
@media screen and (min-width: 1025px) {
  [lang=ko] body .leadership__thum-2 .leadership__img {
    height: 364rem;
  }
}
@media screen and (min-width: 1025px) {
  [lang=ko] body .leadership__bottom {
    margin-top: 116rem;
  }
}
[lang=ko] body .leadership__text {
  bottom: -135rem;
  font-weight: 700;
  font-size: 52rem;
  line-height: 1.15;
  letter-spacing: -0.5rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  [lang=ko] body .leadership__text {
    bottom: -13rem;
    font-size: 36rem;
    line-height: 1.22;
    letter-spacing: -0.2rem;
  }
}
@media screen and (max-width: 750px) {
  [lang=ko] body .leadership__text {
    bottom: -224rem;
    font-weight: 700;
    font-size: 24rem;
    line-height: 1.33;
    letter-spacing: -0.2rem;
    transform: translateY(-50%);
  }
}
[lang=ko] body .leadership__people {
  position: absolute;
  bottom: 56rem;
  left: 50%;
  transform: translate(-50%, 0);
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  [lang=ko] body .leadership__people {
    bottom: 27rem;
  }
}
@media screen and (max-width: 750px) {
  [lang=ko] body .leadership__people {
    bottom: 20rem;
  }
}
@media screen and (min-width: 1025px) {
  [lang=ko] body .leadership__person:nth-child(1) {
    margin-bottom: 0rem;
  }
}
@media screen and (max-width: 750px) {
  [lang=ko] body .leadership__person:nth-child(1) {
    margin-bottom: -1rem;
  }
}
[lang=ko] body .leadership__person:nth-child(1) .leadership__job {
  left: auto;
  right: calc(100% + 7rem);
}
@media screen and (min-width: 1025px) {
  [lang=ko] body .leadership__person:nth-child(1) .leadership__job {
    bottom: 7rem;
  }
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  [lang=ko] body .leadership__person:nth-child(1) .leadership__job {
    right: calc(100% + 4rem);
  }
}
[lang=ko] body .leadership__person:nth-child(2) .leadership__job {
  left: calc(100% + 7rem);
}
@media screen and (min-width: 1025px) {
  [lang=ko] body .leadership__person:nth-child(2) .leadership__job {
    bottom: 5rem;
  }
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  [lang=ko] body .leadership__person:nth-child(2) .leadership__job {
    bottom: 3rem;
    left: calc(100% + 4rem);
  }
}
[lang=ko] body .leadership__job {
  font-family: "abcmonumentgrotesk", "pretendard", sans-serif;
  font-weight: 400;
}
[lang=ko] body .leadership__name {
  font-weight: 500;
  font-size: 40rem;
  line-height: 1.2;
  letter-spacing: -1.25rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  [lang=ko] body .leadership__name {
    font-size: 30rem;
    letter-spacing: -0.2rem;
  }
}
@media screen and (max-width: 750px) {
  [lang=ko] body .leadership__name {
    font-size: 24rem;
    line-height: 1;
    letter-spacing: -0.2rem;
  }
}

.section-news {
  padding: 105rem 232rem 120rem;
  background-color: #f9f9f9;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-news {
    padding: 65rem 30rem 80rem;
  }
}
@media screen and (max-width: 750px) {
  .section-news {
    padding: 50rem 15rem 60rem;
  }
}
.section-news .news__head-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 750px) {
  .section-news .news__head-wrap {
    margin-bottom: 20rem;
  }
}
.section-news .news__head-title {
  font-size: 20rem;
  font-weight: 500;
  line-height: 1;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-news .news__head-title {
    font-size: 16rem;
    line-height: 0.75;
  }
}
@media screen and (max-width: 750px) {
  .section-news .news__head-title {
    font-size: 12rem;
    letter-spacing: -0.1rem;
  }
}
.section-news .news__head-link {
  display: inline-block;
  margin-right: -17rem;
  padding: 17rem;
  font-size: 16rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.1rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-news .news__head-link {
    margin-right: -18rem;
    padding: 18rem;
    font-size: 14rem;
  }
}
@media screen and (max-width: 750px) {
  .section-news .news__head-link {
    margin-right: 0;
    font-size: 12rem;
  }
}
.section-news .news__head-link-wrap--mo {
  display: none;
}
@media screen and (max-width: 750px) {
  .section-news .news__head-link-wrap {
    margin-top: 40rem;
    padding-top: 15rem;
    border-top: 1px solid #000;
    text-align: center;
  }
  .section-news .news__head-link-wrap--mo {
    display: block;
  }
  .section-news .news__head-link-wrap--pc {
    display: none;
  }
}
.section-news .news__head-icon {
  width: 14rem;
  margin-left: 3rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-news .news__head-icon {
    margin-left: 4rem;
  }
}
@media screen and (max-width: 750px) {
  .section-news .news__head-icon {
    width: 13rem;
    margin-left: 6rem;
  }
}
.section-news .news__lists-wrap {
  margin-right: -32rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-news .news__lists-wrap {
    margin-right: -20rem;
  }
}
@media screen and (max-width: 750px) {
  .section-news .news__lists-wrap {
    padding-left: 15rem;
    margin: 0 -15rem;
  }
}
.section-news .news__lists-item {
  width: 33.333%;
  height: auto;
  padding-right: 32em;
  list-style: none;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-news .news__lists-item {
    padding-right: 20em;
  }
}
@media screen and (max-width: 750px) {
  .section-news .news__lists-item {
    padding-right: 15rem;
    width: 95%;
  }
}
.section-news .news__lists-img {
  height: 266rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-news .news__lists-img {
    height: 205rem;
  }
}
@media screen and (max-width: 750px) {
  .section-news .news__lists-img {
    height: 220rem;
  }
}
.section-news .news__lists-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.section-news .news__lists-title {
  min-height: 140rem;
  font-size: 24rem;
  font-weight: 500;
  line-height: 1.17;
  letter-spacing: -0.2rem;
  text-transform: uppercase;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-news .news__lists-title {
    min-height: 100rem;
    font-size: 20rem;
    line-height: 1;
    letter-spacing: -0.1rem;
  }
}
@media screen and (max-width: 750px) {
  .section-news .news__lists-title {
    min-height: 80rem;
    font-size: 16rem;
    line-height: 1;
  }
}
.section-news .news__lists-title-wrap {
  padding-right: 40rem;
  margin-top: 24rem;
}
@media screen and (max-width: 750px) {
  .section-news .news__lists-title-wrap {
    margin-top: 20rem;
  }
}
.section-news .news__lists-link {
  display: inline-block;
  position: relative;
  margin-top: 24rem;
  font-size: 16rem;
  font-weight: 500;
  line-height: 1;
}
.section-news .news__lists-link span {
  display: inline-block;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .section-news .news__lists-link {
    font-size: 14rem;
    line-height: 1.14;
  }
}
@media screen and (max-width: 750px) {
  .section-news .news__lists-link {
    margin-top: 16rem;
    font-size: 12rem;
    letter-spacing: -0.1rem;
  }
}
[lang=ko] body .news__lists-title {
  min-height: 85rem;
  font-weight: bold;
  -webkit-line-clamp: 3;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  [lang=ko] body .news__lists-title {
    min-height: 72rem;
    line-height: 1.2;
    letter-spacing: -0.2rem;
  }
}
@media screen and (max-width: 750px) {
  [lang=ko] body .news__lists-title {
    min-height: 60rem;
    line-height: 1.25;
  }
}
@media screen and (max-width: 750px) {
  [lang=ko] body .news__lists-link {
    margin-top: 36rem;
  }
}